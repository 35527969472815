import { Component, OnInit, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { DialogConfirmComponent } from 'src/app/core/components/dialogs/dialog-confirm/dialog-confirm.component';
import { CrudService } from 'src/app/services/crud.service';
import { onlyNumbers } from 'src/app/utils/utils';

@Component({
	selector: 'app-range-form',
	templateUrl: './range-form.component.html',
	styleUrls: ['./range-form.component.scss'],
	providers: [FormBuilder]
})
export class RangeFormComponent implements OnInit {
	private fb = inject(FormBuilder);
	public dialog = inject(MatDialog);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	showFeedback!: boolean;
	showFeedbackField2!: boolean;

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<RangeFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			qualification_id: [''],
			name: ['', [Validators.required, Validators.minLength(4)]],
			start_range: ['', [Validators.required]],
			end_range: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.form.patchValue(this.data);
			this.updating = true;

		}
	}
	submitForm() {
		// Si el rango final es mayor que el inicial se invalida el proceso y se le indica al usuario la razon
		if (parseInt(this.form.value.start_range) >= parseInt(this.form.value.end_range)) {
			this.dialog.open(DialogConfirmComponent, {
				data: {
					defaultTitle: "No es posible esta acción",
					defaultMessage: "El rango final debe ser mayor que el inicial",
				},
			});
			return;
		}
		
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "qualifications").subscribe({
					next: response => {
						if (response) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "La calificación ha sido creada con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						}
					},
					error: error => {
						// Si no se pudo crear la calificacion se informa para que intente nuevamente
						console.log("Error al crear", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "Ha ocurrido en la creación de la calificación. \n Por favor intente nuevamente",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.qualification_id, this.form.value, "qualifications").subscribe({
					next: response => {
						if (response) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con exito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. \n Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	onlyNumbersAllowed(event: any) {
		const isValidNumber = onlyNumbers(event);
		if(!isValidNumber){
			this.showFeedback = true;
			setTimeout(() => {
				this.showFeedback = false;
			}, 3000);
		}
	}

	onlyNumbersAllowedField2(event: any) {
		const isValidNumber = onlyNumbers(event);
		if(!isValidNumber){
			this.showFeedbackField2 = true;
			setTimeout(() => {
				this.showFeedbackField2 = false;
			}, 3000);
		}
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}

}