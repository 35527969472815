import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MatCardModule,
		MatToolbarModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatSelectModule,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		MatPaginatorModule,
		MatTableModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTableModule,
		MatTabsModule,
		MatMenuModule,
		MatCheckboxModule,
		MatProgressSpinnerModule
	],
	exports: [
		MatCardModule,
		MatToolbarModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatSelectModule,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		MatPaginatorModule,
		MatTableModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTableModule,
		MatTabsModule,
		MatMenuModule,
		MatCheckboxModule,
		MatProgressSpinnerModule

	]

})
export class MaterialModule {
}
