import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


/** Interface */
interface InformationDialogData {
	title: string;
	message: string;
}


@Component({
	templateUrl: './information.dialog.html'
})
export class InformationDialog {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: InformationDialogData,

		public _matDialogRef: MatDialogRef<InformationDialog>) {
	}




	/**
	 * @description Cierra el dialogo
	 */
	public closeDialog(): void {
		this._matDialogRef.close();
	}

}
