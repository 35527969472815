import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudService } from 'src/app/services/crud.service';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
@Component({
	selector: 'app-students',
	templateUrl: './students.component.html',
	styleUrls: ['./students.component.scss'],
	providers: [FormBuilder]
})
export class StudentsComponent implements OnInit {
	private fb = inject(FormBuilder);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	listDocumentType!: [] | any;

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<StudentsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			user_id: [''],
			document_type_id: ['', [Validators.required]], 
			document: ['', [Validators.required]], 
			username: ['', [Validators.required]],
			first_name: ['', [Validators.required]],
			second_name: [''],
			first_lastname: ['', [Validators.required]],
			second_lastname: [''],
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.crudService.getDataBySelectId('students', this.data.user_id).subscribe({
				next: response => {
					this.data = response;
					this.data.user_id = response.id;
					this.form.patchValue(this.data);
				},
				error: error => console.log("Error al obtener los datos del estudiante", error)
			});
			this.updating = true;

		}
		this.crudService.getAll('documenttypes').subscribe({
			next: response => {
				this.listDocumentType = response.data;
			},
			error: error => console.log("Error al obtener los tipos de documentos", error)
		});

	}

	submitForm() {
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "students").subscribe({
					next: data => {
						if (data) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "El estudiante ha sido creado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						} else {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertDangerComponent, {
								data: {
									message: "Ha ocurrido en la creación del estudiante. \n Por favor intente nuevamente",
									icon: "error",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al crear", error);
						const msgError = error.error.errors.msg;
						if(msgError === 'USER_EXISTS_USERNAME') {
							this.form.get('username')?.setErrors({exists: true});
						}
						if(msgError === 'USER_EXISTS_DOCUMENT') {
							this.form.get('document')?.setErrors({exists: true});
						}
						if(msgError === 'USER_EXISTS_USERNAME' || msgError === 'USER_EXISTS_DOCUMENT') {
							this.snackBar.openFromComponent(AlertDangerComponent, {
								data: {
									message: "Nombre de usuario o número de documento ya existe. \n Por favor intente nuevamente",
									icon: "error",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration * 3
							});
						} else {
							this.dialogRef.close(false);
							this.snackBar.openFromComponent(AlertDangerComponent, {
								data: {
									message: "Ha ocurrido en la creación del estudiante. \n Por favor intente nuevamente",
									icon: "error",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						}
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.user_id, this.form.value, "students").subscribe({
					next: data => {
						if (data) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. \n Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

}
