import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconColorComponent } from "./icon-color/icon-color.component";
import { LoadingComponent } from '../../core/components/loading/loading.component';
import { AlertComponent } from './alert/alert.component';
import { AlertDangerComponent } from './alert-danger/alert-danger.component';
import { MaterialModule } from '../material/material.module';
import { TableComponent } from './table/table.component';
import { DialogConfirmComponent } from './dialogs/dialog-confirm/dialog-confirm.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ButtonsModule } from './buttons/buttons.module';
import { BulkLoadComponent } from './bulk-load/bulk-load.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FeedbackTableComponent } from './bulk-load/components/feedback-table/feedback-table.component';

@NgModule({
	declarations: [
		IconColorComponent,
		LoadingComponent,
		AlertComponent,
		AlertDangerComponent,
		TableComponent,
		DialogConfirmComponent,
  	BulkLoadComponent,
   	SkeletonComponent,
		FeedbackTableComponent
	],

	imports: [
		CommonModule,
		MaterialModule,
		NgxPaginationModule,
		FormsModule,
		ButtonsModule,
		MatProgressBarModule
	],

	exports: [
		IconColorComponent,
		TableComponent,
		LoadingComponent,
		BulkLoadComponent,
		SkeletonComponent
	]

})
export class ComponentsModule { }
