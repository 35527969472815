import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabsInterface } from 'src/app/models/tabs.interface';

@Component({
	selector: 'app-button-bulk-load',
	templateUrl: './button-bulk-load.component.html',
	styleUrls: ['./button-bulk-load.component.scss']
})
export class ButtonBulkLoadComponent implements OnInit {
	@Input() tabSelected: TabsInterface | undefined;
	@Input() title!: string;
	@Input() characteristic!: string;
	@Output() outPutNormalCreation = new EventEmitter<string>();
	@Output() outPutBulkLoad = new EventEmitter<void>();

	constructor() { }

	ngOnInit(): void {
	}

	normalCreation(): void {
		this.outPutNormalCreation.emit(this.characteristic);
	}

	bulkLoad(tab: TabsInterface | undefined): void {
		console.log("Bulk creation component", tab);
		this.outPutBulkLoad.emit();
	}
}
