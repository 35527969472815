<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} Sede - Individual</h3>

    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>

  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span>Nombre de la sede</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese nombre de la sede"
        formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El nombre de la sede es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="consecutive_branch"><span class="text-danger fw-bold">*</span>Consecutivo de la sede</label>
      <input matInput type="text" class="form-control" id="consecutive_branch"
        placeholder="Ingrese consecutivo de la sede" formControlName="consecutive_branch" required>
      <mat-hint *ngIf="form.get('consecutive_branch')?.errors && form.get('consecutive_branch')?.touched"
        class="text-danger">
        <small *ngIf="form.get('consecutive_branch')?.hasError('required')">El numero consecutivo de la sede es
          requerido</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="department_id"><span class="text-danger fw-bold">*</span>Departamento</label>
      <select class="form-control bg-white" id="department_id" (change)="selectDepartament($event)"
        formControlName="department_id" required>
        <option selected hidden value="">Selecciona un departamento</option>
        <option *ngFor="let option of departmentsList" [value]="option.department_id">{{option.name | titlecase}}
        </option>
      </select>
      <mat-hint *ngIf="form.get('department_id')?.errors && form.get('department_id')?.touched" class="text-danger">
        <small *ngIf="form.get('department_id')?.hasError('required')">Por favor seleccione un departamento</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="town_id"><span class="text-danger fw-bold">*</span>Municipio</label>
      <select class="form-control bg-white" id="department_id" (change)="selectTown($event)" formControlName="town_id">
        <option selected hidden value="">Selecciona un municipio</option>
        <option *ngFor="let option of townList" [value]="option.town_id">{{option.name | titlecase}}</option>
      </select>
      <mat-hint *ngIf="form.get('town_id')?.errors && form.get('town_id')?.touched" class="text-danger">
        <small *ngIf="form.get('town_id')?.hasError('required')">El municipio es requerido</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="institution_id"><span class="text-danger fw-bold">*</span>Institución</label>
      <select class="form-control bg-white" id="department_id" formControlName="institution_id">
        <option selected hidden value="">Selecciona una institución</option>
        <option *ngFor="let option of institutionsList" [value]="option.institution_id">{{option.name | titlecase}}
        </option>
      </select>
      <mat-hint *ngIf="form.get('institution_id')?.errors && form.get('institution_id')?.touched" class="text-danger">
        <small *ngIf="form.get('institution_id')?.hasError('required')">la institución es requerida</small>
      </mat-hint>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>