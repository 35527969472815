import { Component, Input, OnInit, OnChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationInstance } from "ngx-pagination";
import { TableInterface, TypeTableCrudOptions } from 'src/app/models/table.interface';
import { CrudService } from 'src/app/services/crud.service';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { DepartmentsComponent } from '../../pages/parametrize/components/departments/departments.component';
import { TownsComponent } from '../../pages/parametrize/components/towns/towns.component';
import { IntitutionsComponent } from '../../pages/parametrize/components/intitutions/intitutions.component';
import { BranchesComponent } from '../../pages/parametrize/components/branches/branches.component';
import { CyclesComponent } from '../../pages/parametrize/components/cycles/cycles.component';
import { GroupComponent } from '../../pages/parametrize/components/group/group.component';
import { StudentsComponent } from '../../pages/parametrize/components/students/students.component';
import { RolesFormComponent } from '../../pages/roles/components/roles-form/roles-form.component';
import { Router } from '@angular/router';
import { LearnCategoryComponent } from '../../pages/minigames/components/learn-category/learn-category.component';
import { MinigamesCategoriesComponent } from '../../pages/minigames/components/minigames-categories/minigames-categories.component';
import { MinigamesCharacteristicsComponent } from '../../pages/minigames/components/minigames-characteristics/minigames-characteristics.component';
import { LearnCharacteristicsComponent } from '../../pages/minigames/components/learn-characteristics/learn-characteristics.component';
import { SubMinigamesComponent } from '../../pages/minigames/components/sub-minigames/sub-minigames.component';
import { RangeFormComponent } from '../../pages/recommendations/components/range-form/range-form.component';
import { capitalizeName } from 'src/app/utils/utils';
import { EnrolmentDetailsComponent } from '../../pages/enrollment/components/enrolment-details/enrolment-details.component';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class TableComponent implements OnInit, OnChanges {
	@Input() data!: TableInterface | any;
	@Input() columns!: { value: string, field: string, checked: boolean, hide: boolean, className?: string, classHead?: string }[];
	// Se agrega input para agregar parametro de consulta a la api cuando la tabla es de desempeño (modulo recomendaciones)
	@Input() performance!: boolean;
	@Input() typeTable!: TypeTableCrudOptions;
	@Input() hideOptionTable!: boolean;
	@Input() hideCrudOptions!: boolean;
	@Input() showEditButton!: boolean;
	@Input() showDeleteButton!: boolean;
	@Input() title!: string;
	@Input() showDeletedRegiters: boolean = false;
	@Output() updateTable = new EventEmitter<string>();
	@Output() isRestoreProcess = new EventEmitter<boolean>();
	dataFilter!: any;
	IMAGE_DELETE!: string;
	IMAGE_DELETE_OFF!: string;
	IMAGE_EDIT_OFF!: string;
	IMAGE_TABLE!: string;
	IMAGE_ARROW_DOWN!: string;
	IMAGE_CLOSE!: string;
	IMAGE_MAG_GLASS!: string;
	searchTxt!: string;
	showDeleted: boolean = false;
	dialogRef!: any;

	public paginationInstanceData!: PaginationInstance;
	maxSize: number;
	directionLinks: boolean;
	responsive: boolean;
	previousLabel: string;
	nextLabel: string;

	// Directiva para mostrar u ocultar fila tabla movil
	panelOpenState: boolean = false;
	isLazyLoading: boolean = false;

	constructor(
		private crudService: CrudService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router) {
		this.IMAGE_DELETE = '/assets/pages/users/icon_trash.svg';
		this.IMAGE_DELETE_OFF = '/assets/pages/users/icon_trash_disabled.svg';
		this.IMAGE_EDIT_OFF = '/assets/pages/users/icon_edit_disabled.svg';
		this.IMAGE_TABLE = '/assets/pages/users/users__table.svg';
		this.IMAGE_ARROW_DOWN = '/assets/pages/users/users__arrow-down.svg';
		this.IMAGE_CLOSE = '/assets/pages/dashboard/svg/dashboard__close__grey-300.svg';
		this.IMAGE_MAG_GLASS = '/assets/pages/dashboard/svg/dashboard__search__grey-300.svg';

		this.paginationInstanceData = {
			itemsPerPage: 10,
			currentPage: 1
		};
		this.maxSize = 5;
		this.directionLinks = true;
		this.responsive = false;
		this.previousLabel = '';
		this.nextLabel = '';
	}

	ngOnInit(): void {
	}

	pageSelected(pageNumber: number) {
		this.paginationInstanceData.currentPage = pageNumber;
		if (this.isLazyLoading) {
			this.lazyLoadData();
		}

	}
	setRecordsPerPage(evt: number) {
		this.paginationInstanceData.itemsPerPage = evt;
		if(this.isLazyLoading){
			this.paginationInstanceData.currentPage = 1;
			this.lazyLoadData();
		}
	}

	actualizarSeleccion(evt: any, index: number) {
		if (index === 0 && evt.checked) {
			this.columns.map(prop => prop.checked = true);
		} else if (index > 0 && !evt.checked) {
			this.columns[0].checked = false;
		}
		this.columns[index].checked = evt.checked;

	}

	ngOnChanges() {
		if(this.typeTable === 'students' || this.typeTable === 'users' || this.typeTable === 'minigames' || this.typeTable === 'recommendations' || this.typeTable === 'enrolment') {
			this.isLazyLoading = true;
		} else {
			this.isLazyLoading = false;
		}

		if (this.data) {
			this.dataFilter = this.data;
			// console.log("Recibiendo datos de tabla");
			// console.table(this.dataFilter);
		}

		if (this.dataFilter) {
			// Si no me llegan las fechas le doy formato aleatorio para que no me de error
			this.dataFilter.map((el: { date_modification: string; date_creation: string }) => {
				if (!el.date_modification || !el.date_creation) {
					el.date_modification = "2000-01-01T00:00:00.000Z";
					el.date_creation = "2000-01-01T00:00:00.000Z";
				}
			});

			// Se le da formato a las fechas
			this.dataFilter.map((el: { date_creation: string; }) => el.date_creation = el.date_creation.split('T')[0]);
			this.dataFilter.map((el: { date_modification: string; }) => el.date_modification = el.date_modification.split('T')[0]);

			this.dataFilter.forEach((el: any) => {
				// Si me llega la propiedad town_name la cambio simplemente por name
				if (!el.name && el.town_id) {
					el.name = el.town_name;
				}
				// Si no me llega el nombre del departamento lo obtengo de la propiedad Department para la tabla municipio
				if (!el.department_name && el.Department) {
					el.department_name = el.Department.department_name;
				}
				// Obtengo el nombre de la la ciudad y de la institucion para la tabla institucion
				if (el.branch_id) {
					el.town_name = el.Town.institution_name;
					el.institution_name = el.Institution.institution_name;
				}
				// Si me llega la propiedad username creo el fullname para el estudiante
				if (el.user_id) {
					el.fullname = `${el.first_name} ${el.second_name || ''} ${el.first_lastname} ${el.second_lastname || ''}`;
				}
				// Se crea la propiedad categoria de aprendizaje
				if (el.LearningOutcomeQualifierType) {
					el.learning_categorie = el.LearningOutcomeQualifierType.name;
				}
			});

			this.paginationInstanceData.totalItems = this.dataFilter.length;
		}
		// Cargar de forma paginada los datos de la tabla estudiantes
		if (this.isLazyLoading) {
			this.lazyLoadData();
		}
		this.paginationInstanceData.currentPage = 1;
		this.searchTxt = '';
	}

	lazyLoadData() {
		this.typeTable === 'enrolment' && (this.typeTable = 'students/enrolment');
		this.crudService.getAll(this.typeTable, 
			this.paginationInstanceData.currentPage, 
			this.paginationInstanceData.itemsPerPage, 
			this.searchTxt, undefined, this.showDeleted, this.performance).subscribe({
			next: response => {
				if(response.rows){
					response.rows.map((el: { date_creation: string; }) => el.date_creation = el.date_creation?.split('T')[0]);
					response.rows.map((el: { date_modification: string; }) => el.date_modification = el.date_modification?.split('T')[0]);
					response.rows.forEach((el: any) => {
						// Si me llega la propiedad username creo el fullname para el estudiante
						if (el.user_id || el.id) {
							el.fullname = `${el.first_name} ${el.second_name || ''} ${el.first_lastname} ${el.second_lastname || ''}`;
							el.documentNumber = el.document?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
						}
						// Se crea la propiedad estado para el usuario
						el.status === 0 ? el.status = 'Inactivo' : el.status = 'Activo';
						// Se crea la propiedad rol para el usuario
						if(el.Rols){
							el.role = el.Rols[0].name;
						}
						if(el.LearningOutcomeQualifiers && el.Attributes){
							el.characteristicsMinigame = el.LearningOutcomeQualifiers;
							el.categoriesMinigame = el.Attributes;
						}
						// Se crean las propiedades para la tabla recomendaciones
						if(el.recommendation_id){
							el.name = el.title;
							el.qualification_name = el.Qualification?.name;
							el.attribute_name = el.Attribute?.name;
							el.leaoutqua_name = el.LearningOutcomeQualifier?.name;
							el.grade_name = el.Grade?.name;
							el.type = this.performance ? 'Desempeño' : 'Recomendación';
						}
					});
					this.dataFilter = response.rows;
					this.paginationInstanceData.totalItems = response.count;
				} else {
					// Se da manejo especial para traer los datos de la matricula de estudiantes
					response && response.forEach((el: any) => {
						el.fullname = `${el.first_name} ${el.second_name || ''} ${el.first_lastname} ${el.second_lastname || ''}`;
						el.fullname = capitalizeName(el.fullname);
						el.town_name = capitalizeName(el.town_name);
						el.institution_name = capitalizeName(el.institution_name);
						el.branch_name = capitalizeName(el.branch_name);
						el.grade_name = capitalizeName(el.grade_name);
						el.user_id = el.user_id;
						el.year = el.schyea_name;
						el.documentNumber = el.document?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
					});
					this.dataFilter = response;
					this.paginationInstanceData.totalItems = response[0].rows;
				}
				
			},
			error: err => console.error("Error al obtener los datos de la tabla lazy loading", this.typeTable, err)
		});
	}

	search() {
		if(this.isLazyLoading){
			this.paginationInstanceData.currentPage = 1;
			this.lazyLoadData();
		} else {
			if (this.searchTxt.length > 0) {
				const arrayFilter = this.data.filter((item: { [s: string]: unknown; } | ArrayLike<unknown>) => {
					// Convierte todos los valores de las propiedades del objeto a cadenas, les quito las tildes y las concatena para poder buscar en todos los valores
					const valoresDePropiedades = Object.values(item).map(valor => String(valor).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).join('');
	
					// Si el valor de la propiedad incluye el texto de busqueda lo devuelve
					return valoresDePropiedades.includes(this.searchTxt.toLowerCase());
				});
				this.dataFilter = arrayFilter;
	
			} else {
				this.dataFilter = this.data;
	
			}
			this.paginationInstanceData.totalItems = this.dataFilter.length;
		}
	}

	showRegistersDeleted(event: string) {
		event === 'true' ? this.showDeleted = true : this.showDeleted = false;
		this.isRestoreProcess.emit(this.showDeleted);
	}
	
	restoreData(data: any) {
		this.dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				defaultTitle: 'Reestauración de registro',
				defaultMessage: '¿Estas seguro que quieres restaurar este registro?',
			}
		});
		// Se identifica la propiedad que tiene el id del registro a restaurar
		const id = Object.keys(data).filter(key => key.includes('id'))[0];
		
		if(this.dialogRef){
			this.dialogRef.afterClosed().subscribe((result: any) => {
				if (result) {
					if(this.typeTable === 'roles' || this.typeTable === 'students' || this.typeTable === 'users'){
						data.isRestore = true;
						data.user_id = data[id];
					} 
					if(this.typeTable === 'minigames'){
						data.learning = data.LearningOutcomeQualifiers.map((el: any) => el.name);
						data.attributes = data.Attributes.map((el: any) => el.name) ?? [];
						data.attribute_id = data[id];
						data.isRestore = true;
					}
					this.completeRestoreProcess(data[id], data);
				}
			});
		}
	}

	completeRestoreProcess(id: string | number, data: any) {
		this.crudService.update(id, data, this.typeTable).subscribe({
			next: response => {
				if(response){
					this.updateTable.emit(this.typeTable);
					this.snackBar.openFromComponent(AlertComponent, {
						data: {
							message: `Registro restaurado correctamente`,
							icon: "check_circle",
						},
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000
					});
				}
				this.isRestoreProcess.emit(false);
				this.showDeleted = false;
			}, 
			error: err => {
				console.error("Error al restaurar", err);
				this.snackBar.openFromComponent(AlertDangerComponent, {
					data: {
						message: `No se pudo reestaurar el registro, \n por favor intentalo de nuevo mas tarde`,
						icon: "error",
					},
					verticalPosition: 'top',
					horizontalPosition: 'center',
					duration: 3000
				});
			}
		});
	}

	updateData(data: any) {
		if(!this.showEditButton) {
			this.showModalNoAuth();
			return;
		}
		
		const kindTable = this.typeTable.toLowerCase();
		switch (kindTable) {
			case "departments":
				this.dialogRef = this.dialog.open(DepartmentsComponent, { data });
				break;
			case "towns":
				this.dialogRef = this.dialog.open(TownsComponent, { data });
				break;

			case "institutions":
				this.dialogRef = this.dialog.open(IntitutionsComponent, { data });
				break;

			case "branches":
				this.dialogRef = this.dialog.open(BranchesComponent, { data });
				break;

			case "grades":
				this.dialogRef = this.dialog.open(CyclesComponent, { data });
				break;

			case "groups":
				this.dialogRef = this.dialog.open(GroupComponent, { data });
				break;

			case "students":
				this.dialogRef = this.dialog.open(StudentsComponent, { data });
				break;

			case "roles":
				this.dialogRef = this.dialog.open(RolesFormComponent, { data });
				break;

			case "users":
				this.router.navigate(['main/users/update'], { state: data } );
				break;

			case "learningqualifiertype":
				this.dialogRef = this.dialog.open(LearnCategoryComponent, { data });
				break;

			case "learningqualifier":
				this.dialogRef = this.dialog.open(LearnCharacteristicsComponent, { data });
				break;

			case "attributetypes":
				this.dialogRef = this.dialog.open(MinigamesCategoriesComponent, { data });
				break;

			case "attributes":
				this.dialogRef = this.dialog.open(MinigamesCharacteristicsComponent, { data });
				break;

			case "minigames":
				this.dialogRef = this.dialog.open(SubMinigamesComponent, { data });
				break;

			case "qualifications":
				this.dialogRef = this.dialog.open(RangeFormComponent, { data });
				break;

			case "recommendations":
				if(data.type === 'Recomendación'){
					this.router.navigate([`main/recommendations/recommendation/${data.recommendation_id}`]);
				} else {
					this.router.navigate([`main/recommendations/performance/${data.recommendation_id}`]);
				}
				break;

			case "students/enrolment":
				this.dialogRef = this.dialog.open(EnrolmentDetailsComponent, { data });
				break;

			default:
				console.log("No se ha definido opcion para", kindTable);
				break;
		}

		// Si hay algo en el dialogRef, obtengo su resultado y actualizo la tabla
		if(this.dialogRef){
			this.dialogRef.afterClosed().subscribe((result: any) => {
				if (result) {
					this.updateTable.emit(kindTable);
					window.scrollTo(0, 0);
				}
			});
		}
	}

	deleteData(data: any) {
		if(!this.showDeleteButton) {
			this.showModalNoAuth();
			return;
		}

		const kindTable = this.typeTable.toLowerCase();
		const propId = Object.keys(data).filter(key => key.includes('id'))[0];
		
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				action: kindTable
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.crudService.delete(data[propId], kindTable).subscribe({
					next: value => {
						console.log("Value after delete", value);
						this.updateTable.emit(kindTable);
						this.snackBar.openFromComponent(AlertComponent, {
							data: {
								message: `${kindTable} deleted`,
								icon: "check_circle",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: 3000
						});
					},
					error: error => {
						console.error("Error on delete ", error);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: `${kindTable} fail deleted`,
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: 3000
						});
					},
				});
			}
			window.scrollTo(0, 0);
		});
	}

	clearSearch() {
		this.searchTxt = "";
		this.dataFilter = this.data;
		if (this.isLazyLoading) {
			this.paginationInstanceData.currentPage = 1;
			this.lazyLoadData();
		}
	}

	showModalNoAuth() {
		this.dialog.open(DialogConfirmComponent, {
			data: {
				defaultTitle: 'No autoriazado',
				defaultMessage: 'Lo sentimos, no tienes permisos para realizar esta acción',
				hideButtonCancel: true,
			}
		});
	}

}
