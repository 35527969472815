import { Component, OnInit, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HistoryEnrolment } from 'src/app/models/historyEnrolment.interface';
import { StudentService } from 'src/app/services/student/student.service';
import { capitalizeName, validatePermission } from 'src/app/utils/utils';

@Component({
	selector: 'app-enrolment-details',
	templateUrl: './enrolment-details.component.html',
	styleUrls: ['./enrolment-details.component.scss']
})

export class EnrolmentDetailsComponent implements OnInit {
	columns!: { value: string, field: string, checked: boolean, hide: boolean, className?: string, classHead?: string }[];
	historialEnrolment!: HistoryEnrolment[] | any[];
	messageWithoutData: boolean = false;
	showEditButton: boolean = false;
	fullname: string = '';

	// Control panel para tabla movil
	panelOpenState: boolean = false;

	private studentService = inject(StudentService);
	private router = inject(Router);
	constructor(public dialogRef: MatDialogRef<EnrolmentDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,) { }

	ngOnInit(): void {
		this.fullname = this.data.fullname;
		this.getHistorialEnrolment(this.data.user_id);
		this.generateCharactristicsTable();

	}

	getHistorialEnrolment(id: any) {
		this.studentService.getHistoryEnrolment(id).subscribe({
			next: (historyEnrolment: any[]) => {
				historyEnrolment.forEach(el => {
					el.fullname = `${el.first_name} ${el.second_name} ${el.first_lastname} ${el.second_lastname}`;
					el.enrolment_id = el.Enrolment.enrolment_id;
					el.document_type_id = el.document_type_id;
					el.town_name = capitalizeName(el.Enrolment.Branch.Town.name);
					el.institution_name = capitalizeName(el.Enrolment.Branch.Institution.name);
					el.branch_name = capitalizeName(el.Enrolment.Branch.name);
					el.year = el.Enrolment.SchoolYear.schyea_id;
					el.year_name = el.Enrolment.SchoolYear.name;
					el.grade_name = el.Enrolment.GradeGroup.Grade.name;
					el.group_name = el.Enrolment.GradeGroup.Group.name;
					el.statusEnrolment = el.Enrolment.enrolment_status;
					el.department_id = el.Enrolment.Branch.Town.Department.department_id;
					el.town_id = el.Enrolment.Branch.Town.town_id;
					el.institution_id = el.Enrolment.Branch.Institution.institution_id;
					el.branch_id = el.Enrolment.Branch.branch_id;
					el.grade_id = el.Enrolment.GradeGroup.Grade.grade_id;
					el.group_id = el.Enrolment.GradeGroup.Group.group_id;
				});
				// se ordena el array poniendo primero los registros activos
				this.historialEnrolment = historyEnrolment.sort((a, b) => a.statusEnrolment - b.statusEnrolment);
				this.historialEnrolment.length === 0 && (this.messageWithoutData = true);
			},
			error: (err: any) => console.log(err)
		});
	}

	generateCharactristicsTable() {
		this.columns = <any>[
			{ value: "ID", field: "enrolment_id", checked: true },
			{ value: "Municipio", field: "town_name", checked: true },
			{ value: "Institución", field: "institution_name", checked: true },
			{ value: "Sede", field: "branch_name", checked: false },
			{ value: "Año", field: "year_name", checked: true },
			{ value: "Grado", field: "grade_name", checked: true },
			{ value: "Grupo", field: "group_name", checked: false },
			{ value: "Estado", field: "statusEnrolment", checked: true },
		];
		this.showEditButton = validatePermission('enrollment.edit');
	}

	goFormEditEnrolment(data: HistoryEnrolment) {
		this.dialogRef.close();
		this.router.navigate(['/main/enrollment/update'], { state: { data } });
	}
}
