
<!-- Icono -->
<div
	*ngIf="icon"

	[ngStyle]="{
		'width': width,
		'height': height,
		'-webkit-mask' : 'url(' + icon + ') no-repeat center',
		'mask': 'url(' + icon + ') no-repeat center',
		'background-color': color
	}">
</div>
