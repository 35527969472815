<div class="alert" >
  <div class="icon-msg">
    <div class="side-bar"></div>      
    <img [src]="mainIcon" alt="">
    <p class="text" matSnackBarLabel>
      {{message}}
    </p>
  </div>
  <span class="close-button" matSnackBarActions>
    <button (click)="snackBarRef.dismissWithAction()">
      <img [src]="closeIcon" alt="">
    </button>
  </span>

</div>
