<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} municipio</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="department_id"><span class="text-danger fw-bold">*</span>Departamento</label>
      <select class="form-control bg-white" id="department_id" formControlName="department_id" required>
        <option selected hidden value="">Selecciona un departamento</option>
        <option *ngFor="let option of departmentsList" [value]="option.department_id">{{option.name | titlecase}}</option>
      </select>
      <mat-hint *ngIf="form.get('department_id')?.errors && form.get('department_id')?.touched" class="text-danger">
        <small *ngIf="form.get('department_id')?.hasError('required')">Por favor seleccione un departamento</small>
      </mat-hint>
    </div>
    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span>Nombre del municipio</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese nombre departamento" formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El campo nombre es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>
    <div class="form-group">
      <label for="code_dane"><span class="text-danger fw-bold">*</span>Código DANE</label>
      <input matInput type="text" class="form-control" id="code_dane" placeholder="Ingrese nombre departamento" formControlName="code_dane" required>
      <mat-hint *ngIf="form.get('code_dane')?.errors && form.get('code_dane')?.touched" class="text-danger">
        <small *ngIf="form.get('code_dane')?.hasError('required')">El código DANE del municipio es requerido</small>
      </mat-hint>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>