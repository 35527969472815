<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} Mini juego</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span> Nombre de la caracteristica de mini juego</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese el nombre" formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El nombre de la caracteristica de mini juego es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>
    
		<div class="form-group">
      <label for="num_round"><span class="text-danger fw-bold">*</span> Numero de rondas</label>
      <input matInput type="text" class="form-control" id="num_round" placeholder="Ingrese el numero de rondas" formControlName="num_round" (keypress)="onlyNumber($event)" required>
      <mat-hint *ngIf="form.get('num_round')?.errors && form.get('num_round')?.touched" class="text-danger">
        <small *ngIf="form.get('num_round')?.hasError('required')">La cantidad de rondas es requerida</small>
      </mat-hint>
      <small class="text-danger" *ngIf="showMessage">Por favor solo ingresa numeros</small>
    </div>
		
		<div class="form-group">
      <label for="attributes"><span class="text-danger fw-bold">*</span> Caracteristicas de mini juego</label>
			<mat-select id="attributes" placeholder="Seleccione una o varias opciones" formControlName="attributes" multiple>
				<mat-option *ngFor="let option of listCharacteristicsMinigames" [value]="option.attribute_id">{{option.name | titlecase}}</mat-option>
			</mat-select>
      <mat-hint *ngIf="form.get('attributes')?.errors && form.get('attributes')?.touched" class="text-danger">
        <small *ngIf="form.get('attributes')?.hasError('required')">Por favor seleccione una caracteristica del minijuego</small>
      </mat-hint>
    </div>
		
		<div class="form-group">
			<label for="learning"><span class="text-danger fw-bold">*</span> Caracteristicas de aprendizaje</label>
			<mat-select id="learning" placeholder="Seleccione una o varias opciones" formControlName="learning" multiple>
				<mat-option *ngFor="let option of listCharacteristicsLearning" [value]="option.leaoutqua_id">{{option.name | titlecase}}</mat-option>
			</mat-select>
      <mat-hint *ngIf="form.get('learning')?.errors && form.get('learning')?.touched" class="text-danger">
        <small *ngIf="form.get('learning')?.hasError('required')">Por favor seleccione una caracteristica de aprendizaje</small>
      </mat-hint>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>