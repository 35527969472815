export const E_ROUTER = {
	R_LOGIN: 'login',

	/** Rutas del main */
	R_MAIN: 'main',
	R_MAIN__DASHBOARD: 'dashboard',
	R_MAIN__USERS: 'users',
	R_MAIN__HELP: 'help',
	R_MAIN__PARAMETERIZE: 'parameterize',
	R_MAIN__ROLES: 'roles',
	R_MAIN__MINIGAMES: 'minigames',
	R_MAIN__RECOMMENDATIONS: 'recommendations',
	R_MAIN__REPORTS: 'reports',
	R_MAIN__SETTINGS: 'settings',
	R_MAIN__ENROLLMENT: 'enrollment'
};
