import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { CookieService } from "ngx-cookie-service";
import { Router } from '@angular/router';
import { E_ROUTER } from 'src/app/core/entities/const/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private _cookieService: CookieService, private _router: Router,) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const token: string = this._getToken();

		if (token) {
			request = request.clone({
				setHeaders: {
					authorization: `Bearer ${token}`
				}
			});
		}

		return next.handle(request).pipe(
			catchError((error) => {

				// Catch "401 Unauthorized" responses
				if (error instanceof HttpErrorResponse && (error.status === 401)) {

					// Sign out user
					this._logout().then();

				}

				return throwError(() => error);
			}),
		);;

	}

	/**
	 * @description Retorna el token almacenado
	 *
	 * @private
	 */
	private _getToken(): string {
		return this._cookieService.get('token');

	}
	/**
 * @description Cierra sesión
 */
	private async _logout(): Promise<void> {
		// delete token  from cookie
		this._cookieService.delete('token');
		// delete token and user from local storage to log user out
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		await this._redirectToAuthPage();
	}

	/**
	 * @description Realiza un redireccionamiento al login
	 */
	private async _redirectToAuthPage(): Promise<void> {
		await this._router.navigate([E_ROUTER.R_LOGIN]);
	}
}
