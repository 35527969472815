
<!-- Contenedor principal -->
<div class="main" *ngIf="label">

	<!-- Botón -->
	<button
		class="main__button"

		[textContent]="label"

		(click)="outputClick?.emit()">
	</button>

</div>
