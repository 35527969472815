<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} Ciclo - Individual</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span>Nombre del ciclo</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese nombre ciclo" formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El nombre del ciclo es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>