import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-button-normal',
	templateUrl: './button-normal.component.html',
	styleUrls: ['./button-normal.component.scss']
})
export class ButtonNormalComponent {

	/** Inputs */

	@Input() label!: string;
	@Input() disabled: boolean;



	/** Output */

	@Output() outputClick: EventEmitter<any> | undefined;




	constructor() {

		/** Inputs: valores iniciales */
		this.disabled = false;
	}

}
