import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-confirm',
	templateUrl: './dialog-confirm.component.html',
	styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
	closeImg!: string;
	title!: string;
	message!: string;
	hideButtonCancel!: boolean;
	textCancelButton!: string;
	textConfirmButton!: string;

	constructor(
		public dialogRef: MatDialogRef<DialogConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { 
			action: string, 
			defaultTitle: string, 
			defaultMessage: string, 
			hideButtonCancel: boolean,
			textCancelButton: string,
			textConfirmButton: string
		}
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';

	}
	ngOnInit(): void {
		switch (this.data.action) {
			case "departments":
				this.title = "Eliminar departamento";
				this.message = "¿Estas seguro de eliminar este departamento de manera permanente? Esta acción no se puede deshacer";
				break;

			case "towns":
				this.title = "Eliminar municipio";
				this.message = "¿Estas seguro de eliminar este municipio de manera permanente? Esta acción no se puede deshacer";
				break;

			case "institutions":
				this.title = "Eliminar institución";
				this.message = "¿Estas seguro de eliminar esta institución de manera permanente? Esta acción no se puede deshacer";
				break;
			
			case "branches":
				this.title = "Eliminar sede";
				this.message = "¿Estas seguro de eliminar esta sede de manera permanente? Esta acción no se puede deshacer";
				break;
		
			case "grades":
				this.title = "Eliminar ciclo";
				this.message = "¿Estas seguro de eliminar este ciclo de manera permanente? Esta acción no se puede deshacer";
				break;
		
			case "groups":
				this.title = "Eliminar grupo";
				this.message = "¿Estas seguro de eliminar este grupo de manera permanente? Esta acción no se puede deshacer";
				break;
		
			case "students":
				this.title = "Eliminar estudiante";
				this.message = "¿Estas seguro de eliminar este estudiantede manera permanente? Esta acción no se puede deshacer";
				break;
			
			case "roles":
				this.title =  "Eliminar rol";
				this.message = "¿Estas seguro de eliminar este rol de manera permanente? Esta acción no se puede deshacer";
				break;
			
			case "users":
				this.title = "Eliminar usuario";
				this.message = "¿Estas seguro de eliminar este usuario de manera permanente? Esta acción no se puede deshacer";
				break;

			case "learningqualifiertype":
				this.title = "Eliminar categoría de aprendizaje";
				this.message = "¿Estas seguro de eliminar esta categoría de aprendizaje de manera permanente? Esta acción no se puede deshacer";
				break;
			
			case "learningqualifier":
				this.title = "Eliminar característica de aprendizaje";
				this.message = "¿Estas seguro de eliminar esta característica de aprendizaje de manera permanente? Esta acción no se puede deshacer";
				break;
			
			case "attributetypes":
				this.title = "Eliminar categoría de mini juego";
				this.message = "¿Estas seguro de eliminar esta categoría de mini juego de manera permanente? Esta acción no se puede deshacer";
				break;

			case "attributes":
				this.title = "Eliminar característica de mini juego";
				this.message = "¿Estas seguro de eliminar esta característica de mini juego de manera permanente? Esta acción no se puede deshacer";
				break;

			case "minigames":
				this.title = "Eliminar mini juego";
				this.message = "¿Estas seguro de eliminar este mini juego de manera permanente? Esta acción no se puede deshacer";
				break;

			case "qualifications":
				this.title = "Eliminar rango de calificación";
				this.message = "¿Estas seguro de eliminar este rango de manera permanente? Esta acción no se puede deshacer";
				break;

			case "recommendations":
				this.title = "Eliminar recomendación";
				this.message = "¿Estas seguro de eliminar esta recomendación de manera permanente? Esta acción no se puede deshacer";
				break;

			case "students/enrolment":
				this.title = "Eliminar matricula";
				this.message = "¿Estas seguro de eliminar esta matricula de manera permanente? Esta acción no se puede deshacer";
				break;
		
			default:
				this.title = this.data.defaultTitle;
				this.message = this.data.defaultMessage;
				break;
		}
		this.hideButtonCancel = this.data.hideButtonCancel;
		this.textCancelButton = this.data.textCancelButton;
		this.textConfirmButton = this.data.textConfirmButton;
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

	confirm() {
		this.dialogRef.close(true);

	}
}
