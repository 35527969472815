import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {CookieService} from "ngx-cookie-service";
import {Observable} from "rxjs";
import {E_ROUTER} from "../../core/entities/const/router";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	
	constructor(private _cookieService: CookieService, private _router: Router) {}
	
	
	
	
	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		const existToken: boolean = this._cookieService.check('token');
		
		if (existToken) {
			return true;
		}
		
		this._redirectToAuthPage().then();
		
		return false;
	}
	
	
	
	
	/**
	 * @description Realiza un redireccionamiento al login
	 */
	private async _redirectToAuthPage(): Promise<void> {
		await this._router.navigate([E_ROUTER.R_LOGIN]);
	}

}
