// import { Component, OnInit } from '@angular/core';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {
	snackBarRef = inject(MatSnackBarRef);
	closeIcon!: string;
	mainIcon!: string;
	message!: string;

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string }) {
		this.closeIcon = '/assets/pages/parametrize/close-green.svg';
		this.mainIcon = '/assets/pages/parametrize/check-green.svg';
	}

	ngOnInit(): void {
		switch (this.data.message) {
			case 'departments deleted':
				this.message = "Se ha eliminado el departamento con éxito";
				break;

			case 'towns deleted':
				this.message = "Se ha eliminado el municipio con éxito";
				break;

			case 'institutions deleted':
				this.message = "Se ha eliminado la institución con éxito";
				break;

			case 'branches deleted':
				this.message = "Se ha eliminado la sede con éxito";
				break;

			case 'grades deleted':
				this.message = "Se ha eliminado el ciclo con éxito";
				break;

			case 'groups deleted':
				this.message = "Se ha eliminado el grupo con éxito";
				break;

			case 'students deleted':
				this.message = "Se ha eliminado el estudiante con éxito";
				break;
			
			case 'roles deleted':
				this.message = "Se ha eliminado el rol con éxito";
				break;
						
			case 'users deleted':
				this.message = "Se ha eliminado el usuario con éxito";
				break;

			case 'learningqualifiertype deleted':
				this.message = "Se ha eliminado la categoría de aprendizaje con éxito";
				break;
				
			case 'learningqualifier deleted':
				this.message = "Se ha eliminado la característica de aprendizaje con éxito";
				break;

			case 'attributetypes deleted':
				this.message = "Se ha eliminado la categoría de minijuego con éxito";
				break;

			case 'attributes deleted':
				this.message = "Se ha eliminado la característica de minijuego con éxito";
				break;

			case 'minigames deleted':
				this.message = "Se ha eliminado el mini juego con exito";
				break;

			case 'qualifications deleted':
				this.message = "Se ha eliminado el rango con exito";
				break;

			case 'recommendations deleted':
				this.message = "Se ha eliminado la recomendación con exito";
				break;
			
			case 'students/enrolment deleted':
				this.message = "Se ha eliminado la matricula con exito";
				break;

			default:
				this.message = this.data.message;

				break;
		}
	}

}
