import {Injectable} from '@angular/core';
import {CrudService} from "../crud.service";
import {environment} from "../../../environments/environment";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class StudentService {

	/** Variables generales */

	// Url base
	private readonly baseUrl: string;

	constructor(private crud: CrudService, private request: HttpClient) {
		/** Variables generales: valores iniciales */
		this.baseUrl = `${environment.url}/api/v1/users/filter`;

	}

	/**
	 * @description CRUD: GET
	 */
	public async getStudents(branchId: number, gragroId: number, schoolYear: number): Promise<any> {
		// Válido el parámetro obligatorio
		if ((!branchId && (branchId !== 0)) || (!gragroId && (gragroId !== 0))) { return; }

		// Creo una copia de la url base
		let customBaseUrl: string = this.baseUrl;

		// Agrego el filtro base
		customBaseUrl += '/?baseParam=0';

		// Parámetro cicló, grupo y sede
		customBaseUrl += `&branchId=${branchId}&gragroId=${gragroId}`;

		// Parámetro opcional del año escolar
		if (!!schoolYear) {
			customBaseUrl += `&schoolYear=${schoolYear}`;
		}

		// Obtengo los datos
		return this.crud.get(`${customBaseUrl}`);
	}

	getFiltersStudents(branchId: number, gragroId: number, schoolYear?: number):Observable<any>{
		return schoolYear 
			? this.request.get(`${environment.url}/api/v1/users/filter/?gragroId=${gragroId}&branchId=${branchId}&schoolYear=${schoolYear}}`)
			: this.request.get(`${environment.url}/api/v1/users/filter/?gragroId=${gragroId}&branchId=${branchId}`);
	}

	/** Obtener listado de todos los usuarios */
	getAllUsers():Observable<any>{
		return this.request.get(`${environment.url}/api/v1/users/`);
	}

	findStudent(param: string | any):Observable<any>{
		return this.request.get(`${environment.url}/api/v1/students/find?keyword=${param}`);
	}

	enrollmentStudent(data: any):Observable<any>{
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.request.post(`${environment.url}/api/v1/students/enrolment/create`, data, { headers });
	}

	/** Obtener el historial de matriculas de estudiante */
	getHistoryEnrolment(id: number):Observable<any>{
		return this.request.get(`${environment.url}/api/v1/students/enrolment/${id}`);
	}

	updateEnrolment(id: number, data: any):Observable<any>{
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.request.post(`${environment.url}/api/v1/students/enrolment/${id}/update`, data, { headers });
	}

}
