<div *ngIf="tabSelected?.availableBulkLoad || title; else showNormalButton" class="buttonMenuOptions">
	<app-button mat-button [matMenuTriggerFor]="menu" [title]="'Nuevo'" [matIcon]="'add'" [style]="'primary'"></app-button>
	<mat-menu #menu="matMenu" xPosition="before" class="menuUsers">
		<button mat-menu-item class="buttonItem" (click)="normalCreation()">
			<h4 class="titleItem">Individual</h4>
			<p class="descriptionItem">Creación de {{characteristic.toLowerCase()}} de manera <br> individual.</p>
		</button>
		<button mat-menu-item class="buttonItem" (click)="bulkLoad(tabSelected)">
			<h4 class="titleItem">Carga masiva</h4>
			<p class="descriptionItem">Creación de {{characteristic.toLowerCase()}} de manera masiva <br> por medio de un documento.</p>        
		</button>
	</mat-menu>
</div>
<ng-template #showNormalButton>
	<app-button [title]="'Nuevo'" [matIcon]="'add'" (outputClick)="normalCreation()" [style]="'primary'" class="test">
	</app-button>
</ng-template>