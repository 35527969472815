import {
	FilterSelectOption
} from "../core/pages/dashboard/dashboard-components/dashboard-filter-select/dashboard-filter-select.component";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { UserProfile } from "../models/profile.interface";

declare var require: any;

const moment = require('moment-timezone');


/**
 * @description Método para parsear un arreglo a la estructura de (FilterSelectOption)
 *
 * @param indexName
 * @param labelName
 * @param data
 */
export function arrayToFilterSelectedOption(indexName: string, labelName: string, data: any[]): FilterSelectOption[] {

	// Válido los parámetros
	if (!indexName || !labelName || !data?.length) { return []; }

	// Retorno los datos con la estructura adecuada
	return data?.map(item => ({
		index: item[indexName],
		label: item[labelName]
	}));
}



/**
 * @description Genera un rango de números
 *
 * @param start
 * @param end
 * @param orderIteration
 */
export function generateRangeOfNumbers(start: number, end: number, orderIteration: 'asc' | 'desc' = 'asc'): number[] {

	// Arreglo que almacenara los números
	const numbers: number[] = [];

	// Genero la iteración ascendente
	if (orderIteration === 'asc') {
		for (let i = start; i <= end; i++) { numbers.push(i); }
	}

	// Genero la iteración descendente
	if (orderIteration === 'desc') {
		for (let i = start; i >= end; i--) { numbers.push(i); }
	}

	// Retorno el arreglo
	return numbers;

}



/**
 * @description Fecha actual
 */
export function currentDateNormal(): Date {
	return moment().tz("America/Bogota").toDate();
}



/**
 * @description Fecha actual formateada
 *
 * @param format
 */
export function currentDateFormat(format: string = 'DD/MM/yyyy'): string {
	return moment().tz("America/Bogota").format(format);
}



/**
 * @description
 *
 * @param date
 * @param format
 */
export function dateFormat(date?: Date, format: string = 'DD/MM/yyyy'): string {

	if (!date) { return ''; }

	return moment(date).tz('America/Bogota').format(format);
}

// funcion para que solo se acepte numeros en un input
export function onlyCharacters(event: KeyboardEvent) {
	const pattern = /[0-9]/; // Expresión regular para detectar números
	const inputChar = String.fromCharCode(event.keyCode);

	if (pattern.test(inputChar)) {
		event.preventDefault(); // Prevenir la acción si es un número
	}
}

// funcion para que solo se acepte numeros en un input
export function onlyNumbers(event: KeyboardEvent): boolean {
	const pattern = /[0-9]/;
	const inputChar = String.fromCharCode(event.charCode);
	if (!pattern.test(inputChar)) {
		event.preventDefault();
		return false;
	} else {
		return true;
	}
}

export function validatePermission(permission: string): boolean {
	const user: UserProfile = JSON.parse(localStorage.getItem('user') || '{}');
	const findPermission = user.Rols[0].Permissions.find((perm: any) => perm.value && perm.value.includes(permission));
	if(!findPermission) {
		return false;
	} else {
		return true;
	}
}

/**
 * @description Funcion para generar PDF a partir de un contenedor HTML
 * @author David Barona <davidfbarona@gmail.com>
 * @date 22/06/2023
 * @export
 * @param {string} idElement
 */
export async function generatePDF(idElement: string, nameDocument: string): Promise<void> {
	
	const data: HTMLElement | null = document.getElementById(idElement)!;
	const doc = new jsPDF('p', 'pt', 'a4');
	const options = {
		background: 'white',
		scale: 3
	};
	
	return new Promise(async (resolve, reject) => {
		try {
			await html2canvas(data, options).then((canvas) => {

				const img = canvas.toDataURL('image/PNG');
				// Add image Canvas to PDF
				const bufferX = 30;
				const bufferY = 15;
				const imgProps = (doc as any).getImageProperties(img);
				const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

				const pageHeight = doc.internal.pageSize.getHeight() - 15;

				let heightLeft = pdfHeight;
				let position = 0;
				heightLeft -= pageHeight;

				doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

				while (heightLeft >= 0) {
					position = heightLeft - pdfHeight;
					doc.addPage();
					doc.addImage(img, 'PNG', bufferX, position, pdfWidth, pdfHeight, undefined, 'FAST');
					heightLeft -= pageHeight;
				}

				return doc;
			}).then((docResult) => {
				docResult.save(nameDocument);
				resolve(); 
			});
			
		} catch (error) {
			reject(error);
		}
		
	});
}

export function capitalizeName(name: string): string {
	const words = name.toLowerCase().split(' ');

	for (let i = 0; i < words.length; i++) {
		const word = words[i];
		if (word.length === 2 && i === 0 && word !== 'LA' && word !== 'la' && word !== 'DE' && word !== 'de' && word !== 'EL' && word !== 'el') {
			words[i] = words[i].substring(0, 2).toLocaleUpperCase() + words[i].substring(2);
		} else {
			words[i] = word.charAt(0).toLocaleUpperCase() + word.slice(1);
		}
	}

	return words.join(' ');
}

export function restoreData(
	defaultTitle = 'Ya existe un registro con este nombre',
	defaultMessage = 'Por favor verificar la existencia del registro incluyendo en la sección de registros eliminados, en dicha sección podras reestablecerlo si así lo deseas',
	hideButtonCancel = true) {
	return {
		data: { defaultTitle, defaultMessage, hideButtonCancel }
	};
}