import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudService } from 'src/app/services/crud.service';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { DepartmentInterface } from 'src/app/models/department.interface';
import { InstitutionsInterface } from 'src/app/models/institutes.interface';
import { TownInterface } from 'src/app/models/town.interface';
import { DialogConfirmComponent } from 'src/app/core/components/dialogs/dialog-confirm/dialog-confirm.component';
import { restoreData } from 'src/app/utils/utils';

@Component({
	selector: 'app-branches',
	templateUrl: './branches.component.html',
	styleUrls: ['./branches.component.scss'],
	providers: [FormBuilder]
})
export class BranchesComponent implements OnInit {
	private fb = inject(FormBuilder);
	isSelectOpen!: boolean;
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	departmentsList!: DepartmentInterface[];
	townList!: TownInterface[];
	institutionsList!: InstitutionsInterface[];

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<BranchesComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			branch_id: [''],
			name: ['', [Validators.required]],
			department_id: [''],
			town_id: ['', [Validators.required]],
			institution_id: ['', [Validators.required]],
			consecutive_branch: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {
		if (this.data) {
			console.log("patch value", this.data);
			this.crudService.getAll('towns').subscribe({
				next: response => {
					this.townList = response.data;
					this.townList.forEach((el: any) => el.name = el.town_name);
				}
			});
			this.crudService.getAll('institutions').subscribe({
				next: data => {
					this.institutionsList = data.data;
				}
			});
			this.form.patchValue(this.data);
			this.updating = true;

		}
		this.crudService.getAllDepartments().subscribe({
			next: data => {
				console.log(data.data);
				this.departmentsList = data.data;
			},
			error: error => console.log("Cannot loaded deparments list", error)

		});
	}

	selectDepartament(e: any) {
		this.crudService.getListById(e.target.value, 'towns').subscribe({
			next: data => {
				this.townList = data.data;
			}
		});
	}

	selectTown(e: any) {
		this.crudService.getListById(e.target.value, 'institutions').subscribe({
			next: response => {
				if (response.data.length > 0) {
					this.institutionsList = response.data;
				} else {
					this.crudService.getAll('institutions').subscribe({
						next: data => {
							this.institutionsList = data.data;
						}
					});
				}
			}
		});
	}

	submitForm() {
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "branches").subscribe({
					next: data => {
						if (data.created) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "La sede ha sido creado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						} else {
							this.dialogRef.close(true);
							const data = restoreData();
							this.dialog.open(DialogConfirmComponent, {
								...data
							});
						}
					},
					error: error => {
						// Si no se pudo crear la sede, se informa para que intente nuevamente
						console.log("Error al crear", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "Ha ocurrido en la creación de la sede. \n Por favor intente nuevamente",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.branch_id, this.form.value, "branches").subscribe({
					next: data => {
						if (data) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. \n Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

}
