<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} estudiante</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="document_type_id"><span class="text-danger fw-bold">*</span>Tipo documento</label>
      <select class="form-control bg-white" id="document_type_id" formControlName="document_type_id" required>
        <option selected hidden value="">Selecciona el tipo de documento</option>
        <option *ngFor="let option of listDocumentType" [value]="option.id">{{option.name | titlecase}}</option>
      </select>
      <mat-hint *ngIf="form.get('document_type_id')?.errors && form.get('document_type_id')?.touched" class="text-danger">
        <small *ngIf="form.get('document_type_id')?.hasError('required')">Por favor seleccione un tipo de documento</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="document"><span class="text-danger fw-bold">*</span>Número documento</label>
      <input matInput type="text" class="form-control" id="document" placeholder="Ingrese número documento" formControlName="document" required>
      <mat-hint *ngIf="form.get('document')?.errors && form.get('document')?.touched" class="text-danger">
        <small *ngIf="form.get('document')?.hasError('required')">Por favor digite el número de documento</small>
        <small *ngIf="form.get('document')?.hasError('exists')">Ya existe un usuario con este número de documento.</small>
      </mat-hint>
    </div>

    <div class="form-group" *ngIf="!updating">
      <label for="username"><span class="text-danger fw-bold">*</span>Ingrese el nombre de usuario</label>
      <input matInput type="text" class="form-control" id="username" placeholder="Ingrese nombre de usuario del estudiante" formControlName="username" required>
      <mat-hint *ngIf="form.get('username')?.errors && form.get('username')?.touched" class="text-danger">
        <small *ngIf="form.get('username')?.hasError('required')">El nombre de usuario es requerido</small>
        <small *ngIf="form.get('username')?.hasError('exists')">El nombre de usuario ya esta registrado.</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="first_name"><span class="text-danger fw-bold">*</span>Primer nombre del estudiante</label>
      <input matInput type="text" class="form-control" id="first_name" placeholder="Ingrese primer nombre del estudiante" formControlName="first_name" required>
      <mat-hint *ngIf="form.get('first_name')?.errors && form.get('first_name')?.touched" class="text-danger">
        <small *ngIf="form.get('first_name')?.hasError('required')">El primer nombre del es requerido</small>
      </mat-hint>
    </div>

		<div class="form-group">
      <label for="second_name">Segundo nombre del estudiante</label>
      <input matInput type="text" class="form-control" id="second_name" placeholder="Ingrese segundo nombre del estudiante" formControlName="second_name">
    </div>

		<div class="form-group">
      <label for="first_lastname"><span class="text-danger fw-bold">*</span>Primer apellido del estudiante</label>
      <input matInput type="text" class="form-control" id="first_lastname" placeholder="Ingrese primer apellido del estudiante" formControlName="first_lastname" required>
      <mat-hint *ngIf="form.get('first_lastname')?.errors && form.get('first_lastname')?.touched" class="text-danger">
        <small *ngIf="form.get('first_lastname')?.hasError('required')">El primer apellido del es requerido</small>
      </mat-hint>
    </div>

		<div class="form-group">
      <label for="second_lastname">Segundo apellido del estudiante</label>
      <input matInput type="text" class="form-control" id="second_lastname" placeholder="Ingrese segundo apellido del estudiante" formControlName="second_lastname">
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>