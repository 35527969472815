export const environment = {
	// @ts-ignore
	production: window["env"]["environment"] === 'production',
	// @ts-ignore
	url: window["env"]["url"],
	// @ts-ignore
	urlGame: window["env"]["urlGame"],
	// @ts-ignore
	urlLibrary: window["env"]["urlLibrary"]
};
