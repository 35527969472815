import { Component, OnInit, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { DialogConfirmComponent } from 'src/app/core/components/dialogs/dialog-confirm/dialog-confirm.component';
import { CrudService } from 'src/app/services/crud.service';
import { restoreData } from 'src/app/utils/utils';

@Component({
	selector: 'app-learn-characteristics',
	templateUrl: './learn-characteristics.component.html',
	styleUrls: ['./learn-characteristics.component.scss'],
	providers: [FormBuilder]
})
export class LearnCharacteristicsComponent implements OnInit {
	private fb = inject(FormBuilder);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	leatyp_id!: any[]; // Definir el tipo de dato que se va a recibir

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<LearnCharacteristicsComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			id: [''],
			name: ['', [Validators.required, Validators.minLength(4)]],
			leatyp_id: ['', [Validators.required]],
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.crudService.getDataBySelectId('LearningQualifier', this.data.id).subscribe({
				next: response => {
					console.log("response", response);
					this.form.get('leatyp_id')?.setValue(response.data[0].LearningOutcomeQualifierType.leaoutquatyp_id);
					this.form.get('id')?.setValue(response.data[0].leaoutqua_id);
					this.form.patchValue(response.data[0]);
				},
				error: error => console.log("Error al cargar la característica de aprendizaje", error)
			});
			this.updating = true;

		}
		//Cargar la lista de categorias de acuerdo a la api
		this.crudService.getAll('learningqualifiertype').subscribe({
			next: response => {
				this.leatyp_id = response.data;
			},
			error: error => console.log("Error al cargar las categorias", error)
		});

	}
	submitForm() {
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "LearningQualifier").subscribe({
					next: response => {
						if (response.created) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "La característica de aprendizaje ha sido creado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						} else {
							this.dialogRef.close(false);
							const data = restoreData();
							this.dialog.open(DialogConfirmComponent, {
								...data
							});
						}
					},
					error: error => {
						// Si no se pudo crear el característica de aprendizaje se informa para que intente nuevamente
						console.log("Error al crear", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "Ha ocurrido en la creación de la característica de aprendizaje. \n Por favor intente nuevamente",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.id, this.form.value, "LearningQualifier").subscribe({
					next: response => {
						if (response) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. \n Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}

}