import { Component, OnInit, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { DialogConfirmComponent } from 'src/app/core/components/dialogs/dialog-confirm/dialog-confirm.component';
import { DepartmentInterface } from 'src/app/models/department.interface';
import { TownInterface } from 'src/app/models/town.interface';
import { CrudService } from 'src/app/services/crud.service';
import { restoreData } from 'src/app/utils/utils';

@Component({
	selector: 'app-intitutions',
	templateUrl: './intitutions.component.html',
	styleUrls: ['./intitutions.component.scss'],
	providers: [FormBuilder]
})
export class IntitutionsComponent implements OnInit {
	private fb = inject(FormBuilder);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	departmentsList!: DepartmentInterface[];
	townList!: TownInterface[];

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<IntitutionsComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			institution_id: [''],
			name: ['', [Validators.required, Validators.minLength(4)]],
			dane: ['', [Validators.required]],
			department_id: [''],
			town_id: [''],
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.data.dane = this.data.code_dane;
			this.form.patchValue(this.data);
			this.updating = true;

		}
		this.crudService.getAllDepartments().subscribe({
			next: data => {
				this.departmentsList = data.data;

			}
		});
	}

	selectDepartament(e: any) {
		this.crudService.getListById(e.target.value, 'towns').subscribe({
			next: data => {
				this.townList = data.data;
			}
		});
	}

	submitForm() {
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "institutions").subscribe({
					next: data => {
						if (data.created) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "La institucion ha sido creada con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						} else {
							this.dialogRef.close(true);
							const data = restoreData();
							this.dialog.open(DialogConfirmComponent, {
								...data
							});
						}
					},
					error: error => {
						// Si no se pudo crear el municipio se informa para que intente nuevamente
						console.log("Error al crear", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "Ha ocurrido en la creación del municipio. Por favor intente nuevamente",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.institution_id, this.form.value, "institutions").subscribe({
					next: data => {
						if (data) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con éxito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

}