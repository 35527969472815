import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonNormalComponent} from './button-normal/button-normal.component';
import {ButtonComponent} from './button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {ButtonBulkLoadComponent } from './button-bulk-load/button-bulk-load.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
	declarations: [
		ButtonNormalComponent,
  	ButtonComponent,
		ButtonBulkLoadComponent
	],
	exports: [
		ButtonNormalComponent,
		ButtonComponent,
		ButtonBulkLoadComponent
	],
	imports: [
		CommonModule,
		MatIconModule,
		MatMenuModule
	]
})
export class ButtonsModule {
}
