<div *ngIf="type === 'skeleton'" class="w-full p-4 border border-gray-200 rounded shadow animate-pulse">
	<div class="h-10 bg-gray-300 rounded-2xl w-full mb-4"></div>
	<div class="flex flex-col">
		<div *ngFor="let i of getRange(count)">
			<div class="h-4 bg-gray-200 rounded-full w-64 my-3"></div>
			<div class="w-full h-24 bg-gray-100 rounded-lg"></div>
		</div>
	</div>
	<span class="sr-only">Loading...</span>
</div>

<div *ngIf="type === 'table'" class="w-full rounded animate-pulse">
	<div class="flex justify-between w-full h-8 mt-8">
		<div class="flex gap-2 md:gap-4">
			<div class="bg-gray-200 rounded-md w-24 md:w-64"></div>
			<div class="bg-gray-200 rounded-md w-16 md:w-48"></div>
		</div>
		<div class="bg-gray-200 w-32 md:w-48"></div>
	</div>
	<div class="flex flex-col mt-6">
		<div class="h-12 bg-gray-500 rounded-lg w-full my-1"></div>
		<div *ngFor="let i of getRange(10) let odd = odd">
			<div class="h-8 bg-gray-300 rounded-md w-full my-0.5" [class.bg-gray-100]="odd"></div>
		</div>
	</div>
	<span class="sr-only">Loading...</span>
</div>

<div *ngIf="type === 'tableIndigo'" class="w-full mt-8 rounded animate-pulse">
	<div class="flex justify-between w-full h-8 mt-2">
		<div class="flex gap-2 md:gap-4">
			<div class="bg-indigo-200 w-24 md:w-64"></div>
			<div class="bg-indigo-100 w-16 md:w-48"></div>
		</div>
		<div class="bg-indigo-200 w-32 md:w-48"></div>
	</div>
	<div class="flex flex-col mt-6">
		<div class="h-12 bg-indigo-200 rounded-lg w-full my-1"></div>
		<div *ngFor="let i of getRange(10) let odd = odd">
			<div class="h-8 bg-indigo-100 rounded-md w-full my-0.5" [class.bg-white]="odd"></div>
		</div>
	</div>
	<span class="sr-only">Loading...</span>
</div>

<div *ngIf="type === 'graph'" class="w-full rounded animate-pulse">
	<div class="flex items-baseline justify-center mt-8 gap-20">
		<div class="w-32 bg-orange-400 rounded-t-lg h-72"></div>
		<div class="w-32 bg-blue-500 rounded-t-lg h-48"></div>
		<div class="w-32 bg-red-300 rounded-t-lg h-64"></div>
		<div class="w-32 bg-green-400 rounded-t-lg h-56"></div>
	</div>
	<span class="sr-only">Loading...</span>
</div>

<div *ngIf="type === 'graphGray'" class="w-full rounded animate-pulse">
	<div class="flex items-baseline justify-around mt-8 border-gray-300 border-t-0 border-r-0 border-b-4 border-l-4 md:px-20">
		<div class="w-16 bg-gray-200 rounded-t-lg h-72"></div>
		<div class="w-16 bg-gray-200 rounded-t-lg h-48"></div>
		<div class="w-16 bg-gray-200 rounded-t-lg h-64"></div>
		<div class="w-16 bg-gray-200 rounded-t-lg h-56"></div>
	</div>
	<span class="sr-only">Loading...</span>
</div>

<div *ngIf="type === 'cards'" class="w-full rounded animate-pulse">
	<div class="flex flex-row flex-wrap justify-center md:justify-around gap-0 md:gap-20">
		<div *ngFor="let i of getRange(3)" class="w-[90%] md:w-48 h-20 bg-gray-200 rounded-lg my-2 lg:my-4"></div>
	</div>
	<span class="sr-only">Loading...</span>
</div>