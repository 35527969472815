<div class="feedBackTable">
	<div class="header">
		<h3>{{title}}</h3>
		<p class="text-sm">{{subtitle}}</p>
	</div>

	<div class="body">
		<div class="relative overflow-x-auto table">
			<table class="w-full text-sm text-left text-gray-500 roundTable">
				<thead class="text-lg text-gray-700 bg-gray-200">
					<tr>
						<th scope="col" class="px-6 py-3 text-center" *ngFor="let col of columns">
							{{col.value}}
						</th>
					</tr>
				</thead>

				<tbody>
					<tr class="bg-white border-b " *ngFor="let data of dataFeedback| paginate: paginationInstanceData; let i = index; let odd = odd">
						<td scope="row" class="px-4 py-3 text-center font-medium text-gray-600 "
							*ngFor="let col of columns" [class.bg-gray-50]="odd" [class]="col?.className">
							<span *ngIf="col.field === 'email' && markField(i, 'markEmail') || 
								col.field === 'document' && markField(i, 'markDocument') ||
								col.field === 'username' && markField(i, 'markUsername'); else normalField" class="text-red-500">
								{{ data[col.field] }}
							</span>
							<ng-template #normalField>
								{{ data[col.field] }}
							</ng-template>
						</td>
					</tr>
				</tbody>
			</table>

		</div>

		<div class="tableMobile">
			<mat-expansion-panel *ngFor="let data of dataFeedback | paginate: paginationInstanceData let odd = odd; let i = index"
				(opened)="panelOpenState = true" [class.odd]="!odd" class="table-accordion" (closed)="panelOpenState = false">
				<mat-expansion-panel-header class="expansionHeader">
					<mat-panel-title class="px-2">
						{{ data.fullName }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div *ngFor="let col of columns" class="accordion-content">
					<p>{{col.value }}:
						<span *ngIf="col.field === 'email' && markField(i, 'markEmail') || 
								col.field === 'document' && markField(i, 'markDocument') ||
								col.field === 'username' && markField(i, 'markUsername'); else normalField" class="text-red-500">
							{{ data[col.field] }}
						</span>
						<ng-template #normalField>
							{{ data[col.field] }}
						</ng-template>
					</p>
				</div>
			</mat-expansion-panel>
		</div>

		<div class="flex justify-center items-center w-full mt-3 pagination">
			<pagination-controls class="controls" [maxSize]="maxSize" [directionLinks]="directionLinks"
				[responsive]="responsive" [previousLabel]="previousLabel" [nextLabel]="nextLabel"
				(pageChange)="pageSelected($event)">
			</pagination-controls>
		</div>

		<div *ngIf="!hideNotice">
			<h3 class="text-red-500 font-medium md:mx-3">Nota importante: </h3>
			<p class="text-sm text-gray-500 md:mx-3">Los datos que se encuentran marcados en rojo, se deben cambiar debido a que
				ya estan registrados en base de datos, <br> por favor edite los campos y vuelva a subir el documento</p>

		</div>

	</div>


</div>