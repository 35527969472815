import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudService } from 'src/app/services/crud.service';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { restoreData } from 'src/app/utils/utils';
import { DialogConfirmComponent } from 'src/app/core/components/dialogs/dialog-confirm/dialog-confirm.component';

@Component({
	selector: 'app-roles-form',
	templateUrl: './roles-form.component.html',
	styleUrls: ['./roles-form.component.scss'],
	providers: [FormBuilder]
})
export class RolesFormComponent implements OnInit {
	private fb = inject(FormBuilder);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	list_permission!: [] | any;
	permisssions_activated!: [2, 3] | any;

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<RolesFormComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			rol_id: [''],
			name: ['', [Validators.required, Validators.minLength(4)]],
			permission: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.crudService.getDataBySelectId('roles', this.data.rol_id).subscribe({
				next: response => {
					this.data = response.data[0];
					this.data.permission = response.data[0].Permissions.map((permission: any) => permission.permission_id);
					this.form.patchValue(this.data);
				},
				error: error => console.log('Error on load data to update rol', error)
			});
			this.updating = true;
		}
		this.loadPermissions();

	}

	loadPermissions() {
		this.crudService.getAll('permissions').subscribe({
			next: response => {
				this.list_permission = response.data;
			},
			error: error => console.log('Error on load permissions', error)
		});
	}

	submitForm() {
		if (this.form.valid) {
			// Si no se esta actualizando, se procede a crear el rol
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), 'roles').subscribe({
					next: response => {
						if (response.created) {
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									type: 'success',
									message: 'El rol ha sido creado con éxito.'
								},
								duration: this.duration,
								verticalPosition: 'top',
								horizontalPosition: 'center',
							});
							this.dialogRef.close(true);
						} else {
							this.dialogRef.close(false);
							const data = restoreData();
							this.dialog.open(DialogConfirmComponent, {
								...data
							});
						}
					},
					error: error => console.log('Error on create rol', error)

				});
			}
			// Se procede a actualizar el rol
			else {
				this.crudService.update(this.form.value.rol_id, JSON.stringify(this.form.value), 'roles').subscribe({
					next: response => {
						if (response) {
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									type: 'success',
									message: 'Los cambios han sido guardados con éxito'
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration,
							});
							this.dialogRef.close(true);
						} else {
							this.snackBar.openFromComponent(AlertDangerComponent, {
								data: {
									message: 'No se ha logrado guardar los cambios. \n Por favor intente nuevamente.'
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration,
							});
							this.dialogRef.close(false);
						}
					},
					error: error => console.log('Error on update rol', error)
				});
			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}

}
