import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { TypeTableCrudOptions } from '../models/table.interface';

/** Types */
// Tipos de array para los parámetros
type OptionsParams = string | number | boolean;

// Opciones del POST
export type Options = {
	headers?: HttpHeaders | {
		[header: string]: string | string[];
	};
	context?: HttpContext;
	observe?: 'body';
	params?: HttpParams | {
		[param: string]: OptionsParams | readonly OptionsParams[];
	};
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class CrudService {
	constructor(
		private _httpClient: HttpClient) { }

	/**
	 * @description CRUD: GET
	 */
	public get(url: string, options?: Options): Promise<any> {
		return new Promise((resolve, reject) => {
			this._httpClient.get(url, options).subscribe((response: any) => {
				if (!response) {
					reject();
					return;
				}
				resolve(response.data ?? response);
			}, error => {
				resolve(error);
			});
		});
	}

	/**
	 * @description CRUD: POST
	 */
	public post(url: string, body: any | null, options?: Options): Promise<any> {
		return new Promise((resolve, reject) => {
			this._httpClient.post(url, body, options).subscribe((response: any) => {
				if (!response) {
					reject();
					return;
				}
				resolve(response.data ?? response);
			}, error => {
				resolve(error);
			});
		});
	}


	/** CRUD de todos las interfaces */
	getAllDepartments(): Observable<any> {
		return this._httpClient.get(`${environment.url}/api/v1/departments/filter`);

	}

	getAllByFilters(type: TypeTableCrudOptions, removed?: boolean): Observable<any> {
		return this._httpClient.get(`${environment.url}/api/v1/${type}/filter?removed=${removed}`);
		
	}
	
	getDataBySelectId(type: TypeTableCrudOptions, id: string | number): Observable<any> {
		return this._httpClient.get(`${environment.url}/api/v1/${type}/${id}/select`);

	}

	getAll(
		type: TypeTableCrudOptions, 
		page?: number, 
		size?: number, 
		search?: string, 
		year?: number, 
		removed?: boolean,
		kind?: boolean 
	): Observable<any> {
		!page ? page = 1 : page;
		!size ? size = 10 : size;
		!search ? search = "" : search;
		!year ? year = undefined : year;
		removed = removed ?? false;

		if(!kind){
			return this._httpClient.get(`${environment.url}/api/v1/${type}/?page=${page}&pagesize=${size}&search=${search}&year=${year}&removed=${removed}`);
		} else {
			return this._httpClient.get(`${environment.url}/api/v1/${type}/?page=${page}&pagesize=${size}&search=${search}&year=${year}&type=performance`);
		}

	}

	getListById(id: string | number, type: TypeTableCrudOptions): Observable<any> {
		return this._httpClient.get(`${environment.url}/api/v1/${type}/filter/${id}`);

	}

	create(data: any, type: TypeTableCrudOptions): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this._httpClient.post(`${environment.url}/api/v1/${type}/create/`, data, { headers });

	}

	update(id: any, data: any, type: TypeTableCrudOptions): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this._httpClient.patch(`${environment.url}/api/v1/${type}/${id}/update`, data, { headers });

	}

	delete(data: any, type: string): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this._httpClient.delete(`${environment.url}/api/v1/${type}/${data}/delete/`, { headers });

	}

	/**
	 * Servicio para descargar el archivo plantilla de excel para la carga masiva
	 * @param param Por ejemplo users, students
	*/
	downloadFile(param: string, secondParam?: string): Observable<any> {
		const capitalizeParam = param.charAt(0).toUpperCase() + param.slice(1);
		if(secondParam){
			const capitalizeSecondParam = secondParam?.charAt(0).toUpperCase() + secondParam?.slice(1);
			return this._httpClient.get(`${environment.url}/api/v1/${param}/getExcelBulk${capitalizeSecondParam}`, { responseType: 'blob'});
		}
		return this._httpClient.get(`${environment.url}/api/v1/${param}/getExcelBulk${capitalizeParam}`, { responseType: 'blob'});

	}

	/**
	 * Servicio para subir el archivo de excel para la carga masiva
	 * @param file Archivo de excel
	 * @param param Por ejemplo users, students
	*/
	uploadFile(file: File, param: string, secondParam?: string): Observable<any> {
		const formData = new FormData();
		formData.append('excelFile', file, file.name);
		const capitalizeParam = param.charAt(0).toUpperCase() + param.slice(1);
		if(secondParam){
			const capitalizeSecondParam = secondParam?.charAt(0).toUpperCase() + secondParam?.slice(1);
			return this._httpClient.post(`${environment.url}/api/v1/${param}/bulk${capitalizeSecondParam}`, formData);
		}
		return this._httpClient.post(`${environment.url}/api/v1/${param}/bulk${capitalizeParam}`, formData);

	}
}
