import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
type ButtonType = 'primary' | 'normal';
type behaviorType = 'submit' | 'reset' | 'button';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@Input() title!: string;
	@Input() matIcon!: string;
	@Input() icon!: string;
	@Input() style!: ButtonType;
	@Input() behavior!: behaviorType;
	@Output() outputClick = new EventEmitter<void>();

	constructor() { }

	ngOnInit(): void {
		if (!this.behavior) {
			this.behavior = 'button';
		}
	}

	triggerAction() {

		this.outputClick.emit();
	}
}
