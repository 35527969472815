import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


/** Interface */
interface ConfirmationDialogData {
	title: string;
	message: string;
	buttonCancel?: string;
	buttonConfirm?: string;
}


@Component({
	templateUrl: './confirmation.dialog.html'
})
export class ConfirmationDialog {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,

		public _matDialogRef: MatDialogRef<ConfirmationDialog>) {


		/** Valores por defecto para los botones de cancelar y confirmar */
		data.buttonCancel = (data.buttonCancel ?? 'Cancelar');
		data.buttonConfirm = (data.buttonConfirm ?? 'Confirmar');
	}




	/**
	 * @description Cierra el dialogo
	 */
	public closeDialog(response?: boolean): void {
		this._matDialogRef.close(response);
	}

}
