<div class="detailsEnrolment" *ngIf="historialEnrolment && historialEnrolment.length > 0; else withoutHistoryEnrolment">
  <div class="header">
    <h3>Historial de matricula</h3>
    <p class="text-sm">Estudiante: <span class="font-bold">{{fullname | titlecase}}</span></p>
  </div>

  <div class="body">
    <div class="relative overflow-x-auto table">
      <table class="w-full text-sm text-left text-gray-500 roundTable">
        <thead class="text-lg text-gray-700 bg-gray-200">
          <tr>
            <th scope="col" class="px-6 py-3 text-center" *ngFor="let col of columns" [class.d-none]="!col.checked">
              {{col.value}}
            </th>
            <th scope="col" class="px-6 py-3 text-center">
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b " *ngFor="let data of historialEnrolment; let i = index; let odd = odd">
            <th scope="row" class="px-6 py-4 text-center font-medium text-gray-600 whitespace-nowrap" *ngFor="let col of columns" [class.d-none]="!col.checked" [class.bg-gray-50]="odd">
              {{col.field !== 'statusEnrolment' ? data[col.field] : data[col.field] === 0 ? 'Activa' : data[col.field] === 1 ? 'Cerrada' : 'Anulada' }} <br>
              {{col.field === 'institution_name' ? 'Sede: ' + data['branch_name'] : ''}}
              {{col.field === 'grade_name' ? 'Grupo: ' + data['group_name'] : ''}}
            </th>
            <th [class.bg-gray-50]="odd" [class.d-none]="!showEditButton">
              <div *ngIf="data.statusEnrolment === 0; else viewHistoryEnrolment">
                <span title="Editar matricula">
                  <button class="btn btn-transparent focus:shadow-none" (click)="goFormEditEnrolment(data)">
                    <span class="visually-hidden">Editar matricula</span>
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
              </div>
              <ng-template #viewHistoryEnrolment>
                <span title="Ver historico">
                  <button class="btn btn-transparent" (click)="goFormEditEnrolment(data)">
                    <span title="Ver historico" class="visually-hidden">Ver historico</span>
                    <mat-icon>history</mat-icon>
                  </button>
                </span>
              </ng-template>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tableMobile">
      <mat-expansion-panel *ngFor="let data of historialEnrolment let odd = odd" (opened)="panelOpenState = true" [class.odd]="!odd" class="table-accordion" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title class="px-2">
          {{data.name || data.fullname}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let col of columns" class="accordion-content">
        <p>{{col.value }} {{data[col.field]}}</p>
      </div>      
      <div class="flex py-3 ms-2" [class.d-none]="!showEditButton">
        <div *ngIf="data.statusEnrolment === 'Activa'; else showButtonHistoric">
          <app-button [title]="'Editar matricula'" [matIcon]="'edit'" [style]="'primary'"></app-button>
        </div>
        <ng-template #showButtonHistoric>
          <app-button [title]="'Ver historico'" [matIcon]="'history'" [style]="'primary'"></app-button>
        </ng-template>
      </div>
    </mat-expansion-panel>
    </div>
  </div>
</div>
<ng-template #withoutHistoryEnrolment>
  <div class="detailsEnrolment">
    <div class="header">
      <h3>Historial de matricula</h3>
      <p class="text-sm">Estudiante: <span class="font-bold">{{fullname | titlecase}}</span></p>
    </div>
    <div class="body">
      <div class="flex justify-center items-center">
        <p class="text-gray-500 text-center">No se encontraron matriculas para este estudiante.</p>
      </div>
    </div>
  </div>
</ng-template>