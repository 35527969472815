import { Component, OnInit, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDangerComponent } from 'src/app/core/components/alert-danger/alert-danger.component';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { CrudService } from 'src/app/services/crud.service';
import { onlyNumbers } from "../../../../../utils/utils";

@Component({
	selector: 'app-sub-minigames',
	templateUrl: './sub-minigames.component.html',
	styleUrls: ['./sub-minigames.component.scss'],
	providers: [FormBuilder]
})

export class SubMinigamesComponent implements OnInit {
	private fb = inject(FormBuilder);
	closeImg!: string;
	form!: FormGroup;
	updating!: boolean;
	duration = 3000;
	/* listCharacteristics hace referencia a los atributos */
	listCharacteristicsMinigames!: any[];
	/* listCharacteristicsLearning hace referencia a la variable learning del formulario
	caracteristicas de minijuegos */
	listCharacteristicsLearning!: any[];
	showMessage: boolean = false;

	constructor(
		private crudService: CrudService,
		public dialogRef: MatDialogRef<SubMinigamesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar
	) {
		this.closeImg = '/assets/pages/dashboard/svg/dashboard__close__black.svg';
		this.form = this.fb.group({
			id: [''],
			name: ['', [Validators.required, Validators.minLength(4)]],
			num_round: ['', [Validators.required]],
			attributes: ['', [Validators.required]],
			learning: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {
		if (this.data) {
			this.crudService.getDataBySelectId('minigames', this.data.id).subscribe({
				next: response => {
					response.data[0].attributes = response.data[0].Attributes.map((el: any) => el.attribute_id);
					response.data[0].learning = response.data[0].LearningOutcomeQualifiers.map((el: any) => el.leaoutqua_id);
					response.data[0].id = response.data[0].minigame_id;
					console.log("response details minigame", response.data[0]);
					this.form.patchValue(response.data[0]);
				},
				error: error => console.log("Error al cargar detalles mini juego para actualizar", error)
			});
			this.updating = true;

		}
		this.crudService.getAll('attributes').subscribe({
			next: response => {
				console.log("response listcharacteristics attributes", response.data);
				this.listCharacteristicsMinigames = response.data;
			},
			error: error => console.log("Error al cargar la lista de categorias ", error)
		});
		this.crudService.getAll('LearningQualifier').subscribe({
			next: response => {
				response.data.forEach((el: any) => {
					el.leaoutqua_id = el.id;
				});
				this.listCharacteristicsLearning = response.data;
			},
			error: error => console.log("Error al cargar la lista de caracteristicas aprendizaje ", error)
		});
	}

	submitForm() {
		console.log("form values", this.form.value);
		if (this.form.valid) {
			if (!this.updating) {
				this.crudService.create(JSON.stringify(this.form.value), "minigames").subscribe({
					next: response => {
						if (response) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Mini juego ha sido creado con exito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});
						}
					},
					error: error => {
						// Si no se pudo crear el mini juego se informa para que intente nuevamente
						console.log("Error al crear", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "Ha ocurrido en la creación del mini juego. \n Por favor intente nuevamente",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});
			} else {
				// Se procede a actualizar 
				this.crudService.update(this.form.value.id, this.form.value, "minigames").subscribe({
					next: response => {
						if (response) {
							this.dialogRef.close(true);
							this.snackBar.openFromComponent(AlertComponent, {
								data: {
									message: "Los cambios se han guardado con exito",
									icon: "check_circle",
								},
								verticalPosition: 'top',
								horizontalPosition: 'center',
								duration: this.duration
							});

						}
					},
					error: error => {
						console.log("Error al actualizar ", error);
						this.dialogRef.close(false);
						this.snackBar.openFromComponent(AlertDangerComponent, {
							data: {
								message: "No se ha logrado guardar los cambios. \n Por favor intente nuevamente.",
								icon: "error",
							},
							verticalPosition: 'top',
							horizontalPosition: 'center',
							duration: this.duration
						});
					}
				});

			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	onlyNumber(event: any) {
		const isValidNumber = onlyNumbers(event);
		if(!isValidNumber){
			this.showMessage = true;
			setTimeout(() => {
				this.showMessage = false;
			}, 3000);
		}
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}

}
