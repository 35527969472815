<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} Característica <br> de mini juego</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
		<div class="form-group">
      <label for="atttyp_id"><span class="text-danger fw-bold">*</span> Categoría de mini juego</label>
      <select class="form-control bg-white" id="atttyp_id" formControlName="atttyp_id" required>
        <option selected hidden value="">[categoria]</option>
        <option *ngFor="let option of listCategories" [value]="option.id">{{option.name | titlecase}}</option>
      </select>
      <mat-hint *ngIf="form.get('atttyp_id')?.errors && form.get('atttyp_id')?.touched" class="text-danger">
        <small *ngIf="form.get('atttyp_id')?.hasError('required')">Por favor seleccione una categoria</small>
      </mat-hint>
    </div>

    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span> Nombre de la característica de mini juego</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese el nombre" formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El nombre de la característica de mini juego es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>
    
		<div class="form-group">
      <label for="description"><span class="text-danger fw-bold">*</span> Descripción de la característica de mini juego</label>
			<textarea class="form-control mt-1" id="description" rows="4" formControlName="description" placeholder="Describe el mini juego"></textarea>
      <mat-hint *ngIf="form.get('description')?.errors && form.get('description')?.touched" class="text-danger">
        <small *ngIf="form.get('description')?.hasError('required')">El nombre de la característica de mini juego es requerido</small>
        <small *ngIf="form.get('description')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>