import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-icon-color',
	templateUrl: './icon-color.component.html',
	styleUrls: ['./icon-color.component.scss']
})
export class IconColorComponent {

	/* Inputs */

	// Icon
	@Input() icon!: string;

	// Ancho
	@Input() width: string;

	// Alto
	@Input() height: string;

	// Color
	@Input() color: string;




	public constructor() {

		/* Inputs: valores iniciales */
		this.width = '30px';
		this.height = '30px';
		this.color = '#FFFFFF';
	}

}
