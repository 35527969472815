<!-- Titulo -->
<h1 mat-dialog-title [textContent]="data.title"></h1>

<!-- Contenido -->
<div mat-dialog-content [textContent]="data.message"></div>

<!-- Acciones -->
<div mat-dialog-actions>

	<!-- Botón de cerrar -->
	<button
		mat-button
		cdkFocusInitial
		(click)="closeDialog()">

		Cerrar
	</button>

</div>
