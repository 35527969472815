<div class="dialog">
	<div class="close-image">
		<button
			class="btn btn-transparent"
			(click)="closeDialog()"
		>
			<span class="visually-hidden">Cerrar</span>
			<img
				[src]="closeImg"
				alt=""
			/>
		</button>
	</div>

	<h3 class="title">{{ title }}</h3>

	<p
		class="sub-title"
		[innerHTML]="message"
	></p>

	<div class="buttons">
		<app-button
			[title]="textCancelButton || 'Cancelar'"
			[style]="'normal'"
			(click)="closeDialog()"
			[class.d-none]="hideButtonCancel"
		></app-button>
		<app-button
			[title]="
				!textConfirmButton
					? data.action
						? 'Eliminar'
						: hideButtonCancel
						? 'Aceptar'
						: 'Confirmar'
					: textConfirmButton
			"
			[style]="'primary'"
			(click)="confirm()"
		></app-button>
	</div>
</div>
