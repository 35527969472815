import { Component, Input, OnInit } from '@angular/core';

// Se declaran los tipos de skeleton que se pueden usar
type skeletonType = 'skeleton' | 'table' | 'tableIndigo' | 'graph' | 'graphGray' | 'cards';

@Component({
	selector: 'app-skeleton',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss']
})

export class SkeletonComponent implements OnInit {
	@Input() type!: skeletonType;
	@Input() count!: number;
	constructor() { }

	ngOnInit(): void {
		!this.type && (this.type = 'skeleton');
		!this.count && (this.count = 4);
	}

	getRange(n: number): number[] {
		return Array.from({ length: n }, (_, i) => i);
	}

}
