<!-- Titulo -->
<h1 mat-dialog-title [textContent]="data.title"></h1>

<!-- Contenido -->
<div mat-dialog-content [textContent]="data.message"></div>

<!-- Acciones -->
<div mat-dialog-actions>

	<!-- Botón de cancelar -->
	<button
		mat-button
		(click)="closeDialog()">

		{{ data.buttonCancel }}
	</button>

	<!-- Botón de confirmar -->
	<button
		mat-button
		cdkFocusInitial
		(click)="closeDialog(true)">

		{{ data.buttonConfirm }}
	</button>

</div>
