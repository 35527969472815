<div class="dialog">
  <div class="close-image">
    <button class="btn btn-transparent" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar</span>
      <img [src]="closeImg" alt="">
    </button>
  </div>

  <div class="head">
    <h3 class="title">{{updating ? 'Editar' : 'Crear'}} rango</h3>
    
    <p class="sub-title">Por favor ingrese los siguientes datos</p>

  </div>  
  
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name"><span class="text-danger fw-bold">*</span>Nombre del rango</label>
      <input matInput type="text" class="form-control" id="name" placeholder="Ingrese el nombre" formControlName="name" required>
      <mat-hint *ngIf="form.get('name')?.errors && form.get('name')?.touched" class="text-danger">
        <small *ngIf="form.get('name')?.hasError('required')">El nombre de la categoria de aprendizaje es requerido</small>
        <small *ngIf="form.get('name')?.hasError('minlength')">Este campo debe tener al menos 4 caracteres</small>
      </mat-hint>
    </div>
    
		<div class="form-group">
      <label for="start_range"><span class="text-danger fw-bold">*</span>Rango inicial</label>
      <input matInput type="text" class="form-control" id="start_range" placeholder="Ingrese rango inicial" formControlName="start_range" (keypress)="onlyNumbersAllowed($event)" required>
      <mat-hint *ngIf="form.get('start_range')?.errors && form.get('start_range')?.touched" class="text-danger">
        <small *ngIf="form.get('start_range')?.hasError('required')">El rango inicial es requerido</small>
      </mat-hint>
      <small class="text-danger" *ngIf="showFeedback">Por favor solo ingresa numeros</small>
    </div>

		<div class="form-group">
      <label for="end_range"><span class="text-danger fw-bold">*</span>Rango final</label>
      <input matInput type="text" class="form-control" id="end_range" placeholder="Ingrese rango final" formControlName="end_range" (keypress)="onlyNumbersAllowedField2($event)" required>
      <mat-hint *ngIf="form.get('end_range')?.errors && form.get('end_range')?.touched" class="text-danger">
        <small *ngIf="form.get('end_range')?.hasError('required')">El rango final es requerido</small>
      </mat-hint>
      <small class="text-danger" *ngIf="showFeedbackField2">Por favor solo ingresa numeros</small>
    </div>

    <div class="buttons">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()">
      </app-button>
      <app-button [title]="updating ? 'Guardar' : 'Crear'" [style]="'primary'" [behavior]="'submit'">
      </app-button>
    </div>
  </form>
</div>