<div *ngIf="dataFilter; else skeleton" class="container-table">
  <div class="header-table">
    <h3 *ngIf="title" class="title">{{title}}</h3>

    <div class="col-4 search">
      <input type="text" placeholder="Buscar" [(ngModel)]="searchTxt" (ngModelChange)="search()" (keyup.enter)="search()">
      <img *ngIf="searchTxt.length > 0" class="close-img" [src]="IMAGE_CLOSE" alt="" (click)="clearSearch()">
      <img class="mag-glass-img" [src]="IMAGE_MAG_GLASS" alt="" (click)="search()">
    </div>

    <div class="col-1 col-md-3" [class.d-none]="hideOptionTable">
      <button class="optionsTable" [matMenuTriggerFor]="menu">
        <span class="visually-hidden">Opciones de tabla</span>
        <img [src]="IMAGE_TABLE" alt="">
        Seleccionar columnas
        <img [src]="IMAGE_ARROW_DOWN" alt="">
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <div *ngFor="let opt of columns; let i = index">
          <mat-checkbox (change)="actualizarSeleccion($event, i)" (click)="$event.stopPropagation()"
            [checked]="opt.checked" color="primary" class="mt-2">
            {{opt.value}}
          </mat-checkbox>
        </div>
      </mat-menu>
    </div>
    
    <div class="col-auto ms-auto show-records" [class.d-none]="hideOptionTable">
      <select *ngIf="showDeletedRegiters" [(ngModel)]="showDeleted" (ngModelChange)="showRegistersDeleted($event)">
        <option value='false'>Registros activos</option>
        <option value='true'>Registros eliminados</option>
      </select>
      <button [mat-menu-trigger-for]="options">
        <span class="visually-hidden">Mostrar registros</span>
        Mostrar: {{paginationInstanceData.itemsPerPage}}
        <img [src]="IMAGE_ARROW_DOWN" class="img-arrowdown" alt="">
      </button>
      <mat-menu #options="matMenu" xPosition="before">
        <button class="ps-3 pe-4" mat-menu-item (click)="setRecordsPerPage(5)">5 registros por pagina</button>
        <button class="ps-3 pe-4" mat-menu-item (click)="setRecordsPerPage(10)">10 registros por pagina</button>
        <button class="ps-3 pe-4" mat-menu-item (click)="setRecordsPerPage(15)">15 registros por pagina</button>
        <button class="ps-3 pe-4" mat-menu-item (click)="setRecordsPerPage(20)">20 registros por pagina</button>
      </mat-menu>
    </div>
  </div>
  
  <table class="table">
    <thead class="thead">
      <tr>
        <th *ngFor="let col of columns" [class.d-none]="col.hide || !col.checked" [class]="col?.classHead">
          {{col.value}}</th>
        <th scope="col" class="icons-update-delete" [class.d-none]="hideCrudOptions || !showEditButton && !showDeleteButton"></th>
        <th scope="col" *ngIf="typeTable.includes('students/enrolment')"></th>
      </tr>
    </thead>
    <tbody class="tbody">
      <tr *ngFor="let data of dataFilter | paginate: paginationInstanceData let odd = odd" [class.odd]="odd">
        <td *ngFor="let col of columns" [class.d-none]="col.hide || !col.checked" [class]="col?.className">
          <div [ngClass]="{'user-active': data[col.field] === 'Activo', 'user-inactive': data[col.field] === 'Inactivo'}">
            <span *ngIf="col?.className === 'cellAsArray'; else normalField" class="cellAsArray">
              <p *ngFor="let item of data[col.field]">{{item.name}}</p>
            </span>
            <ng-template #normalField>
              {{data[col.field]}}
            </ng-template>
          </div>
        </td>
        <td scope="col" [class.d-none]="hideCrudOptions || !showEditButton && !showDeleteButton">
          <span *ngIf="showDeleted; else normalRegisters" [title]="'Restaurar registro'">
            <button class="btn btn-transparent" (click)="restoreData(data)">
              <span class="visually-hidden">Restaurar registro</span>
              <mat-icon class="mt-1">settings_backup_restore</mat-icon>
            </button>
          </span>
          <ng-template #normalRegisters>
            <span [title]="data.rol_id === 1 ? 'No puedes editar este campo' : 'Editar'">
              <button class="btn btn-tranparent mt-1 me-4" (click)="updateData(data)" [disabled]="data.rol_id === 1">
                <span class="visually-hidden">Editar </span>
                <span *ngIf="data.rol_id === 1 || !showEditButton; else normalIconEdit">
                  <img [src]="IMAGE_EDIT_OFF" alt="Eliminar">
                </span>
                <ng-template #normalIconEdit>
                  <mat-icon class="mt-1">edit</mat-icon>
                </ng-template>
              </button>
            </span>
            <span [title]="data.rol_id === 1 ? 'No puedes elimnar este campo' : 'Eliminar'">
              <button class="btn btn-tranparent mt-1 text-danger" (click)="deleteData(data)" [disabled]="typeTable === 'roles' && data.rol_id === 1 || typeTable === 'users' && data.id === 1">
                <span class="visually-hidden">Eliminar </span>
                <span *ngIf="typeTable === 'roles' && data.rol_id === 1 || !showDeleteButton || typeTable === 'users' && data.id === 1; else normalIcon">
                  <img [src]="IMAGE_DELETE_OFF" alt="Eliminar">
                </span>
                <ng-template #normalIcon>
                  <img [src]="IMAGE_DELETE" alt="Eliminar">
                </ng-template>
              </button>
            </span>
          </ng-template>
        </td>
        <!-- Muestro esta columna en caso que la tabla sea de la matricula -->
        <td scope="col" *ngIf="typeTable.includes('students/enrolment')">
          <span title="Ver historico matricula">
            <button class="btn btn-transparent" (click)="updateData(data)">
              <span class="visually-hidden">Ver historico matricula estudiante</span>
              <mat-icon>arrow_outward</mat-icon>
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="table-mobile">
    <mat-expansion-panel *ngFor="let data of dataFilter | paginate: paginationInstanceData let odd = odd"
      (opened)="panelOpenState = true" [class.odd]="!odd" class="table-accordion" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{data.name || data.fullname}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let col of columns" [class.d-none]="col.hide || !col.checked" class="accordion-content">
        <p>{{col.value }}</p>
        <span *ngIf="col?.className === 'cellAsArray'; else normalField" class="cellAsArray">
          <p *ngFor="let item of data[col.field]">{{item.name}}</p>
        </span>
        <ng-template #normalField>
          <p>{{data[col.field]}}</p>
        </ng-template>

      </div>
      <div class="icons-update-delete" [class.d-none]="hideCrudOptions || !showEditButton && !showDeleteButton">
        <span [title]="data.rol_id === 1 ? 'No puedes editar este campo' : 'Editar'">
          <button class="btn btn-tranparent mt-1 me-4" (click)="updateData(data)" [disabled]="data.rol_id === 1">
            <span class="visually-hidden">Editar </span>
            <span *ngIf="data.rol_id === 1 || !showEditButton; else normalIconEdit">
              <img [src]="IMAGE_EDIT_OFF" alt="Eliminar">
            </span>
            <ng-template #normalIconEdit>
              <mat-icon class="mt-1">edit</mat-icon>
            </ng-template>
          </button>
        </span>
        <span [title]="data.rol_id === 1 ? 'No puedes elimnar este campo' : 'Eliminar'">
          <button class="btn btn-tranparent mt-1 text-danger" (click)="deleteData(data)" [disabled]="typeTable === 'roles' && data.rol_id === 1 || typeTable === 'users' && data.id === 1">
            <span class="visually-hidden">Eliminar </span>
            <span *ngIf="typeTable === 'roles' && data.rol_id === 1 || !showDeleteButton || typeTable === 'users' && data.id === 1; else normalIcon">
              <img [src]="IMAGE_DELETE_OFF" alt="Eliminar">
            </span>
            <ng-template #normalIcon>
              <img [src]="IMAGE_DELETE" alt="Eliminar">
            </ng-template>
          </button>
        </span>
      </div>
      <div *ngIf="typeTable.includes('students/enrolment')" class="flex py-3 justify-center">
        <app-button [title]="'Ver historico matriculas'" [matIcon]="'read_more'" [style]="'primary'" (outputClick)="updateData(data)"></app-button>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="my-5 d-flex w-100 justify-content-center align-items-center" *ngIf="dataFilter.length === 0">
    <p *ngIf="showDeleted; else normalMsg">
      No se encontraron registros eliminados
    </p>
    <ng-template #normalMsg>
      No se encontraron resultados para la busqueda de <span class="text-danger mx-1">{{searchTxt}}</span>
    </ng-template>
  </div>
  <div class="pagination" *ngIf="dataFilter.length > 0">
    <pagination-controls class="controls" [maxSize]="maxSize" [directionLinks]="directionLinks"
      [responsive]="responsive" [previousLabel]="previousLabel" [nextLabel]="nextLabel"
      (pageChange)="pageSelected($event)">
    </pagination-controls>
  </div>
</div>
<ng-template #skeleton>
  <app-skeleton [type]="'tableIndigo'"></app-skeleton>
</ng-template>